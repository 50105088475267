/**
Template Name: Material Able Admin Template
Author: #
Email: #
File: style.css
=============
  == Table of Contents==
	- Custom Css
	- General Elements
		-	Modal, Nestable, List, Portlates, Preloader, Calender, To-Do, Materialize general elements, Lables
	- Advance Form Elements
	- Pages
		-	Loader page, Invoice, Tasklist, Task detail, Range-slider, Counter, Maintenance, Color, FAQ,  Panels & wells, Contact card, Auth pages, Flag, Gallery, Products pages, Widget, Email, Social timeline, Datatable, Chat, Blog, Wizard, CRM dashboard, Task board, Issue list, Chartlist, X-editable
============= */
// General theme contents
@import "partials/variables";
@import "partials/mixins";
@import "partials/pages/breadcrumb";
