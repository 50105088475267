

@media print  {
  html,
  body {
    font-size: 12px;
    font-weight: 300;
    color: black !important;
  }
}



// @media print {
//   .isPrinting > * {
//     display: none;
//   }

//   .isPrinting app-print-layout {
//     display: flex;
//   }

//   app-auth.isPrinting {
//     display: none;
//   }

//   .isPrinting,
//   .modal-backdrop.show {
//     opacity: 0;
//   }

//   .isPrinting:last-child {
//     page-break-after: auto;
//   }

//   body {
//     margin: 0 !important;
//     background: #fff !important;
//     color: #000 !important;
//   }

//   main,
//   .main-content,
//   .content {
//     margin: 0 !important;
//     padding: 0 !important;
//     width: 100% !important;
//   }

//   @page {
//     // size: 8.5in 12in !important;  /* width height */
//     size: auto !important; /* auto is the initial value */
//     // margin: 0 !important; /* this affects the margin in the printer settings */
//   }

//   thead {
//     display: table-header-group;
//   }

//   tfoot {
//     display: table-row-group;
//   }

//   tr {
//     page-break-after: always !important;
//   }

//   th {
//     page-break-after: always !important;
//   }

//   td {
//     page-break-after: always !important;
//   }

//   p {
//     word-break: normal !important;
//     white-space: normal !important;
//   }

//   .flex-container {
//     margin-left: 0 !important;
//     margin-right: 0 !important;
//   }

//   .company-heading {
//     font-size: 1.5rem !important;
//   }

//   .header-subheading {
//     font-size: 1.1rem !important;
//   }

//   .normal-text,
//   .bold-text,
//   td,
//   tr.invoice-header > th,
//   tr.invoice-items > td,
//   tr.invoice-footer > td,
//   .summary-table-key,
//   .summary-table-value,
//   .memo-text {
//     font-size: 1.2rem !important;
//   }

//   tr.invoice-header > th,
//   tr.invoice-footer > td {
//     height: 45px !important;
//   }

//   tr.invoice-items > td {
//     padding-bottom: 0.4rem !important;
//     padding-top: 0.4rem !important;
//   }

// }
