
$menu-width-desktop: 240px;
$menu-width-tablet: 90px;
$menu-width-smartphone: 230px;

.BrandBackground{
  background-color:#01abcc;
  margin:10px 10px 10px 10px;
  font-size: 15px;
  color:white;
}

body{
  background-clip: white;
}

// Desktio but small side menu
@media screen and (max-width:900px) and (min-width:768px) {
  main {  // main content padding
    padding-left:40px;
    padding-right:40px;
  }
}
// Desktop full side menu
@media screen and (min-width:901px) {
  main {// main content padding
    margin-left: $menu-width-desktop+10;
    padding-left:50px;
    padding-right:50px;
  }
}


// ************* main header ****************[START]********* Both Mobile and Desktop
.header {
  position: fixed;
  overflow: hidden;
  // background-color: #3c4252 !important;
  background-image: linear-gradient(303deg, #03ab8c, #5867dd) !important;
  width: 100%;
	z-index: 4;
	height:4.8rem;
}

.header .subheader{
  position: fixed;
  overflow: hidden;
  padding:3px !important;
  //padding: 20px 10px;
  width: 100%;
	z-index: 4;
  //background-color: #d4d4d4;
  background-color: #000;
}


.iconbuttonleft{
	//display: none !important;
	color:#ffcc6e !important;
	padding-left: 1rem !important;
	font-size:1.36rem !important;
	padding-top:0.25rem;
}

.iconbuttonrightClose {
  //display: none !important;
  color: #ffcc6e !important;
  padding-right: 0.5rem !important;
  font-size: 1.66rem !important;
  padding-top: 0.25rem;
}

.content{
  padding-top:5rem;
  margin-bottom: 6rem;
}

// ************* main header ****************[END]*********


/************ Side Bar ***************/

$color-2nd:#3c4252!important;
$menu-width-desktop: 240px;
$menu-width-tablet: 90px;
$menu-width-smartphone: 230px;

.menu{
	background:$color-2nd;
	height:100vh;
	width:$menu-width-desktop;
	position:fixed;
	top:0;
	left:0;
	z-index:5;
    outline:none;
    color: white;

	.avatar{
		background:rgba(0,0,0,0.1);
		padding:2em 0.5em;
		text-align:center;

		img{
            width:70px;
            height:80px;
			      overflow:hidden;
		}

		h2{
			font-weight:normal;
			margin-bottom:0;
		}
	}

	ul{
		list-style:none;
		padding:0.5em 0;
		margin:0;

		li{
			padding:0.5em 1em 0.5em 3em;
			font-size:0.95em;
			font-weight:regular;
			background-repeat:no-repeat;
			background-position:left 15px center;
			background-size:auto 20px;
			transition:all 0.15s linear;
			cursor:pointer;

			&:hover{
				background-color:rgba(0,0,0,0.1);
			}

			&:focus{
				outline:none;
			}

		}
	}
}


/************ SIDE BAB TABLET *************[START]**/
@media screen and (max-width:900px) and (min-width:768px){
	body{
		padding-left:$menu-width-tablet;
	}
	.menu{
		width:$menu-width-tablet;
		.avatar{
			padding:0.5em;
			position:relative;
			img{
                width:48px;
                height: 54px;
			}
			h2{
				opacity:0;
				position:absolute;
				top:50%;
				left:$menu-width-tablet + 10px;
				margin:0;
				min-width:200px;
				border-radius:4px;
				background:rgba(0,0,0,0.4);
				transform:translate3d(-20px,-50%,0);
				transition:all 0.15s ease-in-out;
			}
		}

		ul{
			li{
				height:$menu-width-tablet/1.5;
				background-position:center center;
				background-size:30px auto;
				position:relative;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				padding: 0%;
				span{
          font-size: 0.7rem;
				}
			}
      li:hover{
			  background-color: #01abcc;
			}
		}
	}
}
/************ SIDE BAB TABLET **************[END]*/



@media screen and (min-width:901px) {
    .sidemenu-btn {
      margin:20px;
    }
}

@media screen and (max-width:400px){
	body{
		padding-left:0;
	}

	.menu{
		width:$menu-width-smartphone;
		box-shadow:0 0 0 100em rgba(0,0,0,0);
		transform:translate3d(-$menu-width-smartphone,0,0);
		transition:all 0.3s ease-in-out;

		.smartphone-menu-trigger{
			width:40px;
			height:40px;
			position:absolute;
			left:100%;
			background:$color-2nd;

			&:before, &:after{
				content:'';
				width:50%;
				height:2px;
				background:#fff;
				border-radius:10px;
				position:absolute;
				top:45%;
				left:50%;
				transform:translate3d(-50%, -50%, 0);
			}

			&:after{
				top:55%;
				transform:translate3d(-50%, -50%, 0);
			}
		}

		ul{

			li{
				padding:1em 1em 1em 3em;
				font-size:1.2em;
			}
		}

		&:focus{
			transform:translate3d(0,0,0);
			box-shadow:0 0 0 100em rgba(0,0,0,0.6);

			.smartphone-menu-trigger{
				pointer-events:none;
			}
		}
	}
}

h2{
	font-size:1.5rem !important;
}


/********** Desktop Side bar End ********************/


