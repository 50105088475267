/* You can add global styles to this file, and also import other style files */
@import "assets/vendors/global/vendors.bundle.css";

.permission-access-hide {
  display: none !important;
}

@font-face {
  font-family: 'Cario';
  src: url('./assets/fonts/cairo/Cairo-Regular.ttf')  format('truetype');
}

@font-face {
    font-family: "Cario";
    src: url('./assets/fonts/cairo/Cairo-Bold.ttf')  format('truetype');
    font-weight: bold;
}

@font-face {
    font-family: "Cario";
    src: url('./assets/fonts/cairo/Cairo-SemiBold.ttf')  format('truetype');
}

@font-face {
    font-family: "Cario";
    src: url('./assets/fonts/cairo/Cairo-Light.ttf')  format('truetype');
    font-weight: 100;
}

