// General variables
$theme-bg-color: #000000;//#ecf0f5;
$theme-font-size:0.9375rem;
$theme-font-color: #373a3c;
$theme-font-family:'Open Sans', sans-serif;
$p-color:#666;
$card-header-color:#37474f;
// Theme colors variables;
$primary-color:#4675cc; //#3c4252; //#448aff;
$warning-color: #ff9600; //#ffba57;
$default-color:#d6d6d6;
$danger-color: #ff5252;
$success-color:#9ccc65;
$inverse-color:#37474f;
$info-color :#00bcd4;
$disabled-color:#448aff;
$white-txt: #fff;
$theme-border:#d6d6d6;
$light-color:#666;

$social-name: facebook,twitter,dribbble,pinterest,youtube,googleplus,instagram,viber,amazon,behance,dropbox;
$social-color: #3B5997,#42C0FB,#EC4A89,#BF2131,#E0291D,#C73E2E,#AA7C62,#7B519D,#000,#0057ff,#3380FF;
$color-name: c-blue,c-red,c-green,c-yellow,c-orenge,c-lite-green,c-purple;
$color-color: #448aff,#ff5252,#9ccc65,#ff9600,#FE8A7D,#69CEC6,#536dfe;
$color-blue: $primary-color;
$color-red: $danger-color;
$color-green: $success-color;
$color-yellow: $warning-color;
$color-orenge: #FE8A7D;
$color-lite-green: #69CEC6;
$color-purple: #536dfe;
$txt-lite-color: #919aa3;
$card-bg-inverce: $inverse-color;
$facebook:#3B5997;
$twitter:#42C0FB;
$dribble:#EC4A89;
$instagram:#AA7C62;
$youtube:#E0291D;
$dark-layout-color: #424242;

$theme-color:(
    primary: (
        base: $primary-color,
        hover: lighten($primary-color, 10%),
        active: darken($primary-color, 12%),
        trans: transparentize($primary-color, 0.5)),
    warning: (
        base: $warning-color,
        hover: lighten($warning-color, 10%),
        active: darken($warning-color, 12%),
        trans: transparentize($warning-color, 0.5)),
    default: (
        base: $default-color,
        hover: lighten($default-color, 0%),
        active: darken($default-color, 12%),
        trans: transparentize($default-color, 0.5)),
    danger: (
        base: $danger-color,
        hover: lighten($danger-color, 10%),
        active: darken($danger-color, 12%),
        trans: transparentize($danger-color, 0.5)),
    success: (
        base: $success-color,
        hover: lighten($success-color, 10%),
        active: darken($success-color, 12%),
        trans: transparentize($success-color, 0.5)),
    inverse: ( base: $inverse-color,
        hover: lighten($inverse-color, 10%),
        active: darken($inverse-color, 12%),
        trans: transparentize($inverse-color, 0.5)),
    info: (
        base: $info-color,
        hover: lighten($info-color, 10%),
        active: darken($info-color, 12%),
        trans: transparentize($info-color, 0.5)),
    disabled: (
        base: $disabled-color,
        hover: lighten($disabled-color, 10%),
        active: darken($disabled-color, 12%),
        trans: transparentize($disabled-color, 0.5))
);
@function theme-color($color-name, $color-variant) {
    @return map-get(map-get($theme-color, $color-name), $color-variant);
}

// Elements color
$border-color:#ddd;
$light-color-text: #fff;
