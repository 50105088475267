// Button mixins start
@mixin buttons($back-color,$border-color,$color:#fff,$cursor:pointer,$transition:all ease-in 0.3s){
	background-color: $back-color;
	border-color:$border-color;
	color:$color;
	cursor:$cursor;
	transition: $transition;
}

@mixin buttons-hover($back-color,$border-color,$color:#fff){
	background-color: $back-color;
	border-color:$border-color;
}

@mixin animations-frame($name){
	@-webkit-keyframes #{$name}{
		@content;
	}
	@keyframes #{$name}{
		@content;
	}
}

@mixin buttons-active($back-color,$border-color,$color:#fff,$box-shadow:none){
	background-color: $back-color;
	border-color:$border-color;
	box-shadow:$box-shadow;
	color:$color;
}

@mixin labels-active($back-color,$border-color,$color:#fff,$box-shadow:none){
	background-color: $back-color;
	border-color:$border-color;
	box-shadow:$box-shadow;
	color:$color;
}

@mixin button-trans($back-color,$border-color){
	background-color:$back-color;
	border-color:$border-color;
}

@mixin buttons-focus($back-color,$color:#fff,$box-shadow:none){
	box-shadow:$box-shadow;
	color:$color;
	background-color:$back-color;
}

@mixin buttons-borders($color,$back-color:#fff){
	color:$color;
	background-color:$back-color;
}

@mixin dropdown-hover($back-color,$color:#fff){
	background-color:$back-color;
	color:$color;
}
// Button mixins end

// Label mixin start
@mixin labels-inverse($border-color,$color){
	border-color: $border-color;
	color:$color;
}

@mixin labels-badge($back-color:#34495e,$color:#fff){
	background-color: $back-color;
	color:$color;
}
@mixin labels-badge-inverse($color,$back-color:#fff){
	color:$color;
	background-color: $back-color;
}
// Label mixin end


@mixin width($width){
	width:$width;
}


@mixin shadow($shadow...){
	box-shadow:$shadow;

}


@mixin theme-keyframes($name) {
	@-webkit-keyframes #{$name} {
        @content;
    }
	@keyframes #{$name} {
        @content;
    }
}


$baseline-px: 16px;
/**
	- PX to REM convertor
	- Usage - @include rem('padding', 16px 0 0 0);
**/
@mixin rem($property, $px-values) {
	// Convert the baseline into rems
	$baseline-rem: $baseline-px / 1rem;

	// Print the first line in pixel values
	#{$property}: $px-values;

	// If there is only one (numeric) value, return the property/value line for it.
	@if type-of($px-values) == "number" {
		#{$property}: $px-values / $baseline-rem;
	} @else {
		// Create an empty list that we can dump values into
		$rem-values: unquote("");
		@each $value in $px-values {
			// If the value is zero or a string or a color, return unchanged input
			@if $value == 0 or type-of($value) == "string" or type-of($value) == "color" {
				$rem-values: append($rem-values, $value); }
			@else {
				$rem-values: append($rem-values, $value / $baseline-rem);
			}
		}
		// Return the property and its list of converted values
		#{$property}: $rem-values;
	}
}


/**
	- PX to REM convertor
	- Usage - padding: _rem(16px 0 0 0);
**/
@function _rem($px-values) {
	// Convert the baseline into rems
	$baseline-rem: $baseline-px / 1rem;

	// If there is only one (numeric) value, return the property/value line for it.
	@if type-of($px-values) == "number" {
		@return $px-values / $baseline-rem;
	} @else {
		// Create an empty list that we can dump values into
		$rem-values: unquote("");
		@each $value in $px-values {
			// If the value is zero or a string or a color, return unchanged input
			@if $value == 0 or type-of($value) == "string" or type-of($value) == "color" {
				$rem-values: append($rem-values, $value); }
			@else {
				$rem-values: append($rem-values, $value / $baseline-rem);
			}
		}
		// Return the property and its list of converted values
		@return $rem-values;
	}
}



// Gradients

// Horizontal gradient, from left to right
//
// Creates two color stops, start and end, by specifying a color and position for each color stop.
// Color stops are not available in IE9 and below.
@mixin bg-gradient-horizontal($start-color: #555, $end-color: #333, $start-percent: 0%, $end-percent: 100%) {
	background-image: -webkit-linear-gradient(left, $start-color $start-percent, $end-color $end-percent); // Safari 5.1-6, Chrome 10+
	background-image: -o-linear-gradient(left, $start-color $start-percent, $end-color $end-percent); // Opera 12
	background-image: linear-gradient(to right, $start-color $start-percent, $end-color $end-percent); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=1); // IE9 and down
}

// Vertical gradient, from top to bottom
//
// Creates two color stops, start and end, by specifying a color and position for each color stop.
// Color stops are not available in IE9 and below.
@mixin bg-gradient-vertical($start-color: #555, $end-color: #333, $start-percent: 0%, $end-percent: 100%) {
	background-image: -webkit-linear-gradient(top, $start-color $start-percent, $end-color $end-percent);  // Safari 5.1-6, Chrome 10+
	background-image: -o-linear-gradient(top, $start-color $start-percent, $end-color $end-percent);  // Opera 12
	background-image: linear-gradient(to bottom, $start-color $start-percent, $end-color $end-percent); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=0); // IE9 and down
}

@mixin bg-gradient-directional($start-color: #555, $end-color: #333, $deg: 45deg) {
	background-repeat: repeat-x;
	background-image: -webkit-linear-gradient($deg, $start-color, $end-color); // Safari 5.1-6, Chrome 10+
	background-image: -o-linear-gradient($deg, $start-color, $end-color); // Opera 12
	background-image: linear-gradient($deg, $start-color, $end-color); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
}
@mixin bg-gradient-horizontal-three-colors($start-color: #00b3ee, $mid-color: #7a43b6, $color-stop: 50%, $end-color: #c3325f) {
	background-image: -webkit-linear-gradient(left, $start-color, $mid-color $color-stop, $end-color);
	background-image: -o-linear-gradient(left, $start-color, $mid-color $color-stop, $end-color);
	background-image: linear-gradient(to right, $start-color, $mid-color $color-stop, $end-color);
	background-repeat: no-repeat;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=1); // IE9 and down, gets no color-stop at all for proper fallback
}
@mixin bg-gradient-vertical-three-colors($start-color: #00b3ee, $mid-color: #7a43b6, $color-stop: 50%, $end-color: #c3325f) {
	background-image: -webkit-linear-gradient($start-color, $mid-color $color-stop, $end-color);
	background-image: -o-linear-gradient($start-color, $mid-color $color-stop, $end-color);
	background-image: linear-gradient($start-color, $mid-color $color-stop, $end-color);
	background-repeat: no-repeat;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=0); // IE9 and down, gets no color-stop at all for proper fallback
}
@mixin bg-gradient-radial($inner-color: #555, $outer-color: #333) {
	background-image: -webkit-radial-gradient(circle, $inner-color, $outer-color);
	background-image: radial-gradient(circle, $inner-color, $outer-color);
	background-repeat: no-repeat;
}
@mixin bg-gradient-striped($color: rgba(255,255,255,.15), $angle: 45deg) {
	background-image: -webkit-linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent);
	background-image: -o-linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent);
	background-image: linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent);
}
